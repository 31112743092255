import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Marked from '../components/Marked'
import SEO from '../components/seo'
import Signature from '../components/Signature'

const AboutPageIndex = props => {
  return (
    <Layout>
      <SEO title="About" />
      <h1>About</h1>
      <Signature />
      <Marked>{props.data.site.siteMetadata.description}</Marked>
    </Layout>
  )
}

export default AboutPageIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
